import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/utils/report-head-traning'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat, time12FormateTraining } from '@/utils/fliter'
const routinePdf = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, newDatas, vm, search) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [{
              columns: reportHeadData.reportHeadColumn
          },
          { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
          { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
          { text: reportHeadData.address, style: 'address', alignment: 'center' },
          { text: reportTitle, style: 'hh', alignment: 'center' }
      ]
        const allRowsHead = []
        if (search.circular_memo_no) {
          const rowItem = [
            { text: vm.$t('elearning_iabm.circular_memo_no'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: search.circular_memo_no, style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (search.date) {
          const rowItem = [
            { text: vm.$t('globalTrans.date'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: dateFormat(search.date), style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (search.fiscal_year) {
          const rowItem = [
            { text: vm.$t('elearning_config.fiscal_year'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.fiscal_year_bn : search.fiscal_year, style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (search.org) {
          const rowItem = [
            { text: vm.$t('elearning_config.organization'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.org_bn : search.org, style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (search.training_type) {
          const rowItem = [
            { text: vm.$t('elearning_config.training_type'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.training_type_bn : search.training_type, style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (search.training_category) {
          const rowItem = [
            { text: vm.$t('elearning_config.training_category'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.training_category_bn : search.training_category, style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (search.training_title) {
          const rowItem = [
            { text: vm.$t('elearning_config.training_title'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.training_title_bn : search.training_title, style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (search.vanue) {
          const rowItem = [
            { text: vm.$t('elearning_tpm.vanue'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.vanue_bn : search.vanue, style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (allRowsHead.length > 0) {
          pdfContent.push({
            table: {
              widths: ['45%', '5%', '50%'],
              body: allRowsHead
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0
              },
              vLineWidth: function (i, node) {
                return 0
              }
            }
          })
        }
        pdfContent.push({ text: '', style: 'fertilizer' })
        const allRows = [
          [
            { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('globalTrans.date'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('globalTrans.time'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('globalTrans.course'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('elearning_tpm.course_module'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('elearning_tpm.trainer'), style: 'th', alignment: 'center', bold: true }
          ]
        ]
        newDatas.forEach((info, index) => {
          allRows.push([
            { text: vm.$n(index + 1), style: 'td', alignment: 'center' },
            { text: dateFormat(info.training_date), style: 'td', alignment: 'center' },
            { text: time12FormateTraining(info.start_time) + '-' + time12FormateTraining(info.end_time), style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? info.course_name_bn : info.course_name, style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? info.course_module_name_bn : info.course_module_name, style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? info.trainer_name_bn : info.trainer_name, style: 'td', alignment: 'center' }
          ])
        })
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['10%', '20%', '20%', '20%', '20%', '10%'],
            body: allRows
          }
        })
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          header: {
            fontSize: 14,
            bold: true,
            alignment: 'center',
            margin: [0, 0, 0, 0]
          },
          org: {
              fontSize: 12,
              bold: true,
              alignment: 'center',
               margin: [0, -25, 0, 0]
          },
          address: {
              fontSize: 11,
              margin: [0, 0, 0, 5]
          },
          hh: {
              fontSize: 13,
              bold: true,
              margin: [10, 10, 25, 20]
          },
          search: {
            fontSize: (i18n.locale === 'bn') ? 10 : 8,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [5, 0, 0, 5]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).download('routine')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  routinePdf
}
